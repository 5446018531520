import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

// import { Container, Row, Col } from "react-bootstrap"

import { StaticImage } from "gatsby-plugin-image"

const SecondPage = () => (
  <Layout>
    <Seo title="Car Charging Station Installation" />
    
    <h1>Car Charging Station Installation</h1>
    <p>To charge your new electric car or plug-in hybrid at home requires the appropriate installation of an electric car charging station. A car charging station converts household AC current into DC for your vehicle. There are more than a dozen manufacturers and many models of electric car charging stations on the market today. Making the right selection can be a challenge if you’ve never purchased one before. </p>
    <p><strong>All Electric is a Tesla certified installer for Tesla cars.</strong></p>
    <p className="text-center">
      <StaticImage
        src="../../images/charging-station.jpg"
        width={600}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Car Charging Station"      
      />
    </p>
    <h2>Practical Advice</h2>
    <p>Master electrician Thomas Tripolt of All-Electric not only provides car charging station installation, he can also offer practical advice on the type of charging station that will best suit your needs. Then, he’ll help you determine the most convenient installation location, cable length, amperage capacity, possible portability and connectivity. Teslas are a particularly favorite electric car around Marin County. Thomas Tripolt has installed dozens of Tesla charging stations. He is a fully licensed contractor, so you can be assured that your installation will meet all state and local safety codes. Rather than working with someone from his team, you will always work with Thomas.</p>
    <p>Following are the electric car or plug-in hybrid car charging stations that Thomas can install: Tesla, BMW, Chevrolet, Fiat, Ford, Honda, Kia, Mercedes, Mitsubishi, Porsche, Toyota, Volkswagen, Volvo, Audi and Lexus.</p>
    
  </Layout>
)

export default SecondPage
